import React, { FC, useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux';
import { useAppSelector as useSelector } from '../../../../../app/common/_customHooks/redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { selectors } from "../../../../../app/user-access/_redux";
import { RootState } from '../../../../../redux/store';

import "./HeaderMenu.scss"
import { useTranslation } from 'react-i18next';

interface IProps {
    selectedTabIndex?:number;
}

const HeaderNavigation:FC<IProps> = ({ selectedTabIndex }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(selectedTabIndex || 0);

    const paths = useSelector((state:RootState) => {
        return selectors.getTabMenu(state);
    }, shallowEqual);
    
    useEffect(() => {
        return () => {
            setTab(0);
        }
    }, []);

    useEffect(() => {
        const { pathname } = location;
        const currentItemIdx =
            paths.findIndex(({url}: {url:string}) => url === pathname);

        if (currentItemIdx >= 0) setTab(currentItemIdx);
    }, [location.pathname, paths]);

    const onTabClick = (index:number, url:string) => {
        setTab(index);
        navigate(url);
    }

    if (!paths || paths.lenght === 0) return <div />;

    return (
        <div className={`NavigationMenuContainer ${paths.lenght > 0 && 'w-100'}`}>
            <ul
                className="nav nav-tabs nav-pills grey-nav-pills align-items-center"
                role="tablist"
            >
                {paths.map(({url, label}: {url:string, label:string}, idx:number) => (
                    <li className="nav-item" onClick={() => onTabClick(idx, url)} key={idx}>
                        <span
                            className={`nav-link cursor-pointer ${tab === idx && "active"}`}
                            data-toggle="tab"
                            role="tab"
                            aria-selected={tab === idx}
                        >
                            {t(`TabNav.${label}`)}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

HeaderNavigation.defaultProps = {
    selectedTabIndex: 0,
}

export default HeaderNavigation