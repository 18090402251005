import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { IRangeOption, ISavedToursPlan, ITerritoryOption, ITour, IToursPlan, IVehicleType } from "../../_interfaces/reduxState";
import { ILocation, ITask } from "../../_interfaces/routeCreation";

interface IState extends IDefaultState<any, any, any> {
    routesLoading: boolean;
    // routes: IArticlesDetailsList[];
    rangeOptions:IRangeOption[];
    rangeOptionsLoading:boolean;
    
    territoryOptions:ITerritoryOption[];
    territoryOptionsLoading:boolean;
    
    locations:ILocation[];
    locationsLoading:boolean;

    tasks:ITask[];
    tasksLoading:boolean;

    vehicleTypes: IVehicleType[];
    vehicleTypesLoading: boolean;

    tourPlanStatus: string;
    tours: IToursPlan | null;
    toursPlanLoading: boolean;

    selectedTour: ITour | null;

    userLocation: {lat:number, lng:number, isSet: boolean};

    savedTour: ISavedToursPlan | null;
    assignedOptions: {
      id: number,
      value: string,
      name: string
    }[]
}
  
const initialState:IState = {
    ...defaultInitialState,
    routesLoading: false,
    
    rangeOptions: [],
    rangeOptionsLoading: false,

    territoryOptions: [],
    territoryOptionsLoading: false,

    tasks: [],
    tasksLoading: false,

    locations: [],
    locationsLoading: false,
    
    vehicleTypes: [],
    vehicleTypesLoading: false,

    tourPlanStatus: '',
    tours: null,
    toursPlanLoading: false,

    selectedTour: null,

    userLocation: {
      lat: 42.69737,
      lng: 23.32886,
      isSet: false,
  },

  savedTour: null,
  assignedOptions: [
    {id: 0, value: '0', name: 'No'}, {id: 1, value: '1', name: 'Yes'}
  ]
}

const reducers = {
  ...defaultReducers,
//   setArticles: (state:IState, action:PayloadAction<IArticlesDetailsList[]>) => {
//     state.articles = action.payload;
//     state.routesLoading = false;
//   },
  estRoutesLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.routesLoading = action.payload;
  },

  setRangeOptions: (state:IState, action: PayloadAction<IRangeOption[]>) => {
    state.rangeOptions = action.payload;
  },
  setRangeOptionsLoading: (state:IState, action: PayloadAction<boolean>) => {
    state.rangeOptionsLoading = action.payload;
  },

  setTerritoryOptions: (state:IState, action: PayloadAction<ITerritoryOption[]>) => {
    state.territoryOptions = action.payload;
  },
  setTerritoryLoading: (state:IState, action: PayloadAction<boolean>) => {
    state.territoryOptionsLoading = action.payload;
  },

  setLocations: (state:IState, action:PayloadAction<ILocation[]>) => {
    state.locations = action.payload
  },
  setLocationsLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.locationsLoading = action.payload
  },

  setTasks: (state:IState, action:PayloadAction<ITask[]>) => {
    state.tasks = action.payload
  },
  setTasksLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.tasksLoading = action.payload
  },

  setVehicleTypes: (state:IState, action:PayloadAction<IVehicleType[]>) => {
    state.vehicleTypes = action.payload
  },
  setVehicleTypesLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.vehicleTypesLoading = action.payload
  },

  setTourPlanStatus: (state:IState, action:PayloadAction<string>) => {
    state.tourPlanStatus = action.payload;
  },
  setTourPlan: (state:IState, action:PayloadAction<IToursPlan | null>) => {
    state.tours = action.payload;
    state.toursPlanLoading = false;
  },
  setTourPlanLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.toursPlanLoading = action.payload;
  },
  setSelectedTour: (state:IState, action:PayloadAction<ITour | null>) => {
    state.selectedTour = action.payload
  },

  setUserLocation: (state:IState, action:PayloadAction<{lat: number, lng:number, isSet: boolean}>) => {
    state.userLocation = action.payload;
  },

  setSavedTour: (state:IState, action:PayloadAction<ISavedToursPlan | null>) => {
    state.savedTour = action.payload;
    state.toursPlanLoading = false;
  }
}

export const routePlanningSlice = createSlice({
  name: "routePlanning",
  initialState,
  reducers,
});