import { customerAttachmentsSlice } from "./customerSlice";
import * as defaultActions from "../../../../../redux/_common";
import { constructInitialFilters } from "../../../../components/common/filters/utils";

import { get } from "../../../../../api/requester";
import { Dispatch } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../../../redux/_helpers";

const { actions } = customerAttachmentsSlice;

export const moduleUrl = "/api/attachments/customerfiles";


export const getFilters = () => async (dispatch:Dispatch) => {
    try {
      const tasktypes:any[] = await get("api/appmasterdata/customer/types/complexSearch");
      const customerFiles:any[] = await get('/api/attachments/customerfiles/complexSearch')
  
      dispatch(actions.setFilter(
        constructInitialFilters([
          ...(tasktypes || []),
          ...(customerFiles || [])
        ])
      ));
    } catch (error) {
  
    }
  }

export const fetchItemDetails = (id:string) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setLoadingSelectedFile(true));
    const details:any[] = await get(`${moduleUrl}/details/${id}`);
    dispatch(actions.setSelectedFile(details?.[0] || {}));
  } catch (error) {
    dispatch(actions.setSelectedFile({}));
  } finally {
    dispatch(actions.setLoadingSelectedFile(false));
  }
}

export const setSelectedFile = (file:any) => (dispatch:Dispatch) => {
  dispatch(actions.setSelectedFile(file));
}

export const fetchImage = async (FileUrl:string) => {
  return await get(`${moduleUrl}/downloadFile`, {
    FileUrl
  }, null, {
    responseType: 'blob',
    timeout: 300000,
  }); 
}

export const downloadFile = (file:any) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setFileDownloading(true));
    dispatch(actions.clearErrors());
    const download = await CustomerAttachmentsActions.fetchImage(file.fileId);
  
    const fileName = `${file.fileId}`;
    const url = window.URL.createObjectURL(new Blob([download], {type: file.mimeType}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    dispatch(actions.setFileDownloading(false));
  } catch (error) {
    dispatch(actions.setFileDownloading(false));
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "error" }));
  }
}

export const downloadFiles = (ids:string[]) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setFileDownloading(true));
    dispatch(actions.clearErrors());
    const files = await get(`${moduleUrl}/downloadZip`, {
      Files: ids.join(',')
    }, null, {
      responseType: 'blob',
      timeout: 300000,
    });

    const today = new Date(Date.now()).toISOString().slice(0, 20).replace(' ', '').replace('.', '');
    const fileName = `customer_attachments_export_${today}.zip`;
    const url = window.URL.createObjectURL(new Blob([files]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    dispatch(actions.setFileDownloading(false));
  } catch (error) {
    dispatch(actions.setFileDownloading(false));
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "error" }));
  }
}

export const setFilter = (filters: any) => defaultActions.setFilters(filters, actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

const CustomerAttachmentsActions = {
  fetchImage: fetchImage,
}

export default CustomerAttachmentsActions;