import { webShopSlice } from "./webShopSlice";
import { get } from "../../../../api/requester";
import * as defaultActions from "../../../../redux/_common";
import { ENDPOINTS } from "../constants";
import { Dispatch } from "redux";
import * as fields from '../fields'
import { IArticle, ICustomer, IOrderType, IWholesaler } from "./_interfaces";

const { actions } = webShopSlice;
export const setValidationErrors = actions.setValidationErrors;

export const clearItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
export const clearAll = () => defaultActions.clearAll(actions);

export const getItems = (params: {}) => defaultActions.getItems(`${ENDPOINTS.ROOT}${ENDPOINTS.ORDERS}`, actions, params);
export const getItem = (id: string) => defaultActions.getItem(`${ENDPOINTS.ROOT}/${id}`, actions, refineDataForTabs);

export const getFilters = () => defaultActions.getFilters(ENDPOINTS.FILTERS, actions);
export const setFilter = (filters: any[]) => defaultActions.setFilters(filters, actions);

export const setSelectedCustomerID = (id:string) => actions.setSelectedCustomerID(id);
export const setPrevCartItems = (items:IArticle[]) => actions.setPrevCartItems(items);

export const saveItem = (asset: {}, id: string | null) =>
  defaultActions.saveItem(
    ENDPOINTS.ROOT,
    actions,
    asset,
    id,
    refineDataForSaving,
  );

export const getOrderTypes = () => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setOrderTypesLoading(true));
    const orderTypes:IOrderType[] = await get('/api/activity/ordertypes/select');
    dispatch(actions.setOrderTypes(orderTypes.map((order:IOrderType) => ({...order, [fields.ORDER_TYPE]: order[fields.ORDER_TYPE_ID]}))));
  } catch (error) {
    dispatch(actions.setOrderTypesLoading(false));
  }
}

export const getWholesalers = (id: string) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setWholesalersLoading(true));
    const wholesalers:IWholesaler[] = await get(`/api/appmasterdata/customers/customersWholesalersList/${id}`);
    dispatch(actions.setWholesalers(wholesalers));
  } catch (error) {
    dispatch(actions.setWholesalersLoading(false));
  }
}


export const getCustomers = () => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setCustomersLoading(true));
    const customers:ICustomer[] = await get('/api/appmasterdata/customers/customersByTerritoryId');
    dispatch(actions.setCustomers(customers));
  }
  catch (error:any) {
    dispatch(actions.setCustomersLoading(false));
  }
}

export const getArticles = (params:any) => async (dispatch:Dispatch) => {
  try {
    if (params.CustomerId) {
      dispatch(actions.setArticlesLoading(true));
      const articles:IArticle[] = await get('/api/appmasterdata/customers/customersArticles', {
        CustomerId: params.CustomerId,
        ...(params.filter ? {Filter: params.filter} : {}),
      });
  
      dispatch(actions.setArticles(articles));
    }

  } catch (error) {
    dispatch(actions.setArticlesLoading(false));
  }
}

export const getCurrentOrderItems = (params: {OrderId:string}) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setOrderItemsLoading(true));
    const orderItems:{details:any[], totalPrice:number} = await get(`${ENDPOINTS.ROOT}${ENDPOINTS.ORDER_ITEMS}`, params);
    dispatch(actions.setOrderItems(refineOrderItems(orderItems)));
  } catch(error) {
    dispatch(actions.setOrderItemsLoading(false));
  }
}

export const getLastOrder = (params: {CustomerId:string}) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setOrderItemsLoading(true));
    const orderItems:any = await get(`${ENDPOINTS.ROOT}${ENDPOINTS.COPY_LAST}`, params);
    dispatch(actions.setPrevCartItems(refineOrderItems(orderItems)));
  } catch (error) {
    dispatch(actions.setOrderItemsLoading(false));
  }
}

const refineOrderItems = (data:any) => {
  const newItems:IArticle[] = data.details.map((item:any) => ({
    id: item.articleCode,
    name: item.articleName,
    code:item.articleCode,
    imageUrl:item.articleUrl,
    price:item.price,
    qty: Number(item.quantity),
  }));

  return newItems;
}

const refineDataForSaving = (data:any) => {
  const orderItems = data[fields.ITEMS].reduce((items:any[], item:any) => [
    ...items,
    ...(Number(item.qty) > 0 ? [{
        articleId: item.id,
        quantity: Number(item.qty),
        price:item.price,
    }] : [])
  ], [])

  return {
    customerId: data[fields.GENERAL][fields.CUSTOMER_ID_LONG],
    [fields.DELIVERY_DATE]: new Date(data[fields.GENERAL][fields.DELIVERY_DATE]).toISOString(), //"2022-10-26T11:59:27.425Z",
    [fields.ORDER_TYPE]: data[fields.GENERAL][fields.ORDER_TYPE],
    [fields.WHOLESALER_ID]: data[fields.GENERAL][fields.WHOLESALER_ID],
    notes: data[fields.GENERAL][fields.NOTES],
    orderItems,
  }
}

const refineDataForTabs = (data: any) => {
  return {
    [fields.GENERAL]: {
      [fields.ID]: data[fields.ID],
      [fields.ORDER_ID]: data[fields.ORDER_ID],
      [fields.CUSTOMER]: data[fields.CUSTOMER],
      [fields.CUSTOMER_ID_LONG]: data[fields.CUSTOMER_ID_LONG],
      [fields.STAUS]: data.taskStatus,
      [fields.DELIVERY_ADDRESS]: data[fields.DELIVERY_ADDRESS],
      [fields.DELIVERY_DATE]: data[fields.DELIVERY_DATE],
      [fields.ORDER_TYPE]: data[fields.ORDER_TYPE],
      [fields.WHOLESALER_ID]: data[fields.WHOLESALER_ID],
      [fields.NOTES]: data[fields.NOTES],
      [fields.CREATED_BY]: data[fields.CREATED_BY],
      [fields.CREATED_BY_ID]: data[fields.CREATED_BY_ID],
      [fields.CREATED_ON]: data[fields.CREATED_ON],
    },
    [fields.ITEMS]: []
  };
};
