import { customersSlice } from "./customersSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import { getCurrentUserOrganizationUnit, getCurrentAccessToken } from "../../../../user-access/organizationUnitStorage";
import { swaggerClient } from "../../../../../api/swaggerApiConnect";
import { put, takeLatest } from "redux-saga/effects";
import { actions as regionsActions } from "../../../DataConfiguration/_redux/regions";
import { actions as citiesActions } from "../../../DataConfiguration/_redux/cities";
import SagasManager from "../../../../../redux/SagasManager";
import * as fields from "../../pages/customers/shared/edit/constants";

import { get } from "../../../../../api/requester";
import { getErrorMessage, getResponseErrorMessage } from "../../../../../redux/_helpers";
import { cloneDeep } from "lodash";
import { constructInitialFilters } from "../../../../components/common/filters/utils";

const { actions } = customersSlice;

export const setValidationErrors = actions.setValidationErrors;

export const QPARAMS_CHANGED = "customers/QParamsChanged";
export const GENERALDATA_QPARAMS_CHANGED = "customers_GeneralData/QParamsChanged";
export const CREDITDATA_QPARAMS_CHANGED = "customers_CreditData/QParamsChanged";
export const CLASSIFICATION_QPARAMS_CHANGED = "customers_ClassificationData/QParamsChanged";
export const BLOCKDATA_QPARAMS_CHANGED = "customers_BlockData/QParamsChanged";
export const WHOLESALERS_QPARAMS_CHANGED = "wholesalers/QParamsChanged";
export const ADVANCED_FILTER_QPARAMS_CHANGED = "advancedFilters/QParamsChanged";

export const fetchCustomersGeneralData = defaultActions.fetchItemsWithDelaySwagger(
  "Customers_GetGeneralData",
  actions,
  GENERALDATA_QPARAMS_CHANGED,
);

export const fetchCustomerCreditData = defaultActions.fetchItemsWithDelaySwagger(
  "Customers_GetCreditData",
  actions,
  CREDITDATA_QPARAMS_CHANGED,
);

export const fetchCustomerClassificationData = defaultActions.fetchItemsWithDelaySwagger(
  "Customers_GetClassificationData",
  actions,
  CLASSIFICATION_QPARAMS_CHANGED,
);

export const fetchCustomerBlockData = defaultActions.fetchItemsWithDelaySwagger(
  "Customers_GetBlockData",
  actions,
  BLOCKDATA_QPARAMS_CHANGED,
);

export const fetchWholesalers = defaultActions.fetchItemsWithDelaySwagger(
  "Customers_GetWholesalers",
  actions,
  WHOLESALERS_QPARAMS_CHANGED
);

export const fetchCustomerWholesalersList = defaultActions.fetchItemsSwagger(
  'Customers_GetWholesalers',
  actions,
  'wholesalers',
  "error",
  'wholesalersCount'
);

export const fetchAdvancedFilter = defaultActions.fetchItemsWithDelaySwagger(
  "Customers_GetAdvancedFilterData",
  actions,
  ADVANCED_FILTER_QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Customers_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "Customers_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const clearErrors = () => defaultActions.clearError(actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Customers_GetById", actions, item => {
  return {
    id: item.id,
    [fields.GENERAL_INFO]: {
      [fields.GENERAL_INFO_CUSTOMER_NUMBER]: item[fields.GENERAL_INFO_CUSTOMER_NUMBER],
      [fields.GENERAL_INFO_CUSTOMER_NAME]: item[fields.GENERAL_INFO_CUSTOMER_NAME],
      [fields.GENERAL_INFO_CUSTOMER_LONG_NAME]: item[fields.GENERAL_INFO_CUSTOMER_LONG_NAME],
      [fields.GENERAL_INFO_CUSTOMER_EMAIL]: item[fields.GENERAL_INFO_CUSTOMER_EMAIL],
      [fields.GENERAL_INFO_CUSTOMER_TELEPHONE]: item[fields.GENERAL_INFO_CUSTOMER_TELEPHONE],
      [fields.GENERAL_INFO_LANGUAGE_ID]: item[fields.GENERAL_INFO_LANGUAGE_ID],
      [fields.GENERAL_INFO_DELIVERY_LEAD_TIME]: item[fields.GENERAL_INFO_DELIVERY_LEAD_TIME],
      [fields.CONTACT_PERSON]: item[fields.CONTACT_PERSON].map((p) => ({
        ...p,
        [fields.CONTACT_PERSON_ACTIVE]: !p[fields.CONTACT_PERSON_ACTIVE]
      }))
    },
    [fields.BILLING_ADDRESS]: item[fields.BILLING_ADDRESS],
    [fields.SHIP_TO_ADDRESS]: item[fields.SHIP_TO_ADDRESS],
    [fields.CREDIT_DATA]: item[fields.CREDIT_DATA],
    [fields.HIERARCHY]: item.hierarchies,
    [fields.CLASSIFICATION]: {
      [fields.CLASSIFICATION_TYPE_ID]: item[fields.CLASSIFICATION_TYPE_ID],
      [fields.CLASSIFICATION_SEGMENT_ID]: item[fields.CLASSIFICATION_SEGMENT_ID],
      [fields.CLASSIFICATION_PAYER_NUMBER]: item[fields.CLASSIFICATION_PAYER_NUMBER],
      [fields.CLASSIFICATION_REBATE_NUMBER]: item[fields.CLASSIFICATION_REBATE_NUMBER],
      [fields.CLASSIFICATION_DISTRIBUTION_TYPE_ID]: item[fields.CLASSIFICATION_DISTRIBUTION_TYPE_ID],
      [fields.CLASSIFICATION_IS_WHOLESALER]: item[fields.CLASSIFICATION_IS_WHOLESALER],
      [fields.DIVISION_ID]: item[fields.DIVISION_ID],
      [fields.EXTERNAL_REFERENCE]: item[fields.EXTERNAL_REFERENCE],
    },
    [fields.BLOCK]: {
      [fields.BLOCK_ORDER]: item[fields.BLOCK_ORDER],
      [fields.BLOCK_DATE_UTC]: item[fields.BLOCK_DATE_UTC],
    },
    [fields.TERRITORIES]: item.customerTerritories,
    [fields.ASSORTMENT]: {
      [fields.CUSTOMER_ASSORTMENT]: item[fields.CUSTOMER_ASSORTMENT]
    },
    [fields.WHOLESALERS]: item.wholesalers,
    [fields.COMMENTS]: item[fields.COMMENTS],
    [fields.CUSTOMER_FILES]: item[fields.CUSTOMER_FILES],
  };
});

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Customers_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger("Customers_Post", actions, item => {
  const files = [];
  const createCustomerFiles = [];
  item[fields.CUSTOMER_FILES].forEach(a => {
    if (a?.action === "create") {
      a.file && files.push(a.file)

      createCustomerFiles.push({
        fileName: a.file?.name || "n/a",
      });
    }
  });

  const form = {
    [fields.CREATE_CUSTOMER_FILES]: createCustomerFiles,
    [fields.GENERAL_INFO_CUSTOMER_NUMBER]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_NUMBER],
    [fields.GENERAL_INFO_CUSTOMER_NAME]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_NAME],
    [fields.GENERAL_INFO_CUSTOMER_LONG_NAME]: setNullValueIfNeeded(item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_LONG_NAME]),
    [fields.GENERAL_INFO_CUSTOMER_EMAIL]: setNullValueIfNeeded(item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_EMAIL]),
    [fields.GENERAL_INFO_CUSTOMER_TELEPHONE]: setNullValueIfNeeded(item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_TELEPHONE]),
    [fields.GENERAL_INFO_LANGUAGE_ID]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_LANGUAGE_ID],
    [fields.GENERAL_INFO_DELIVERY_LEAD_TIME]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_DELIVERY_LEAD_TIME],
    [fields.CONTACT_PERSON]: item[fields.GENERAL_INFO][fields.CONTACT_PERSON].map((person) => ({
      [fields.CONTACT_PERSON_NAME]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_NAME]),
      [fields.CONTACT_PERSON_ROLE]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_ROLE]),
      [fields.CONTACT_PERSON_EMAIL]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_EMAIL]),
      [fields.CONTACT_PERSON_TELEPHONE]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_TELEPHONE]),
      [fields.CONTACT_PERSON_TELEPHONE_TWO]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_TELEPHONE_TWO]),
      [fields.CONTANCT_PERSON_DEFAULT]: person[fields.CONTANCT_PERSON_DEFAULT],
      [fields.CONTACT_PERSON_ACTIVE]: !person[fields.CONTACT_PERSON_ACTIVE],
    })),
    [fields.BILLING_ADDRESS]: {
      [fields.BILLING_ADDRESS_CITY_ID]: item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_CITY_ID],
      [fields.BILLING_ADDRESS_POSTAL_CODE]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_POSTAL_CODE]),
      [fields.BILLING_ADDRESS_STREET]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_STREET]),
      [fields.BILLING_ADDRESS_HOUSE_NUMBER]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_HOUSE_NUMBER]),
      [fields.BILLING_ADDRESS_LONGITUDE]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_LONGITUDE]),
      [fields.BILLING_ADDRESS_LATITUDE]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_LATITUDE]),
    },
    [fields.SHIP_TO_ADDRESS]: {
      [fields.SHIP_TO_ADDRESS_CITY_ID]: item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_CITY_ID],
      [fields.SHIP_TO_ADDRESS_POSTAL_CODE]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_POSTAL_CODE]),
      [fields.SHIP_TO_ADDRESS_STREET]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_STREET]),
      [fields.SHIP_TO_ADDRESS_HOUSE_NUMBER]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_HOUSE_NUMBER]),
      [fields.SHIP_TO_ADDRESS_LONGITUDE]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_LONGITUDE]),
      [fields.SHIP_TO_ADDRESS_LATITUDE]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_LATITUDE]),
    },
    [fields.CREDIT_DATA]: {
      [fields.CREDIT_DATA_BANK_ACCOUNT]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_BANK_ACCOUNT]),
      [fields.CREDIT_DATA_VAT_NUMBER]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_VAT_NUMBER]),
      [fields.CREDIT_DATA_CREDIT_LIMIT]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_CREDIT_LIMIT]),
      [fields.CREDIT_DATA_CREDIT_EXPOSURE]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_CREDIT_EXPOSURE]),
      [fields.CREDIT_DATA_OPEN_BALANCE]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_OPEN_BALANCE]),
    },
    [fields.CLASSIFICATION_TYPE_ID]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_TYPE_ID],
    [fields.CLASSIFICATION_SEGMENT_ID]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_SEGMENT_ID],
    [fields.DIVISION_ID]: item[fields.CLASSIFICATION][fields.DIVISION_ID],
    [fields.CLASSIFICATION_PAYER_NUMBER]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_PAYER_NUMBER],
    [fields.CLASSIFICATION_REBATE_NUMBER]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_REBATE_NUMBER],
    [fields.CLASSIFICATION_DISTRIBUTION_TYPE_ID]:
      item[fields.CLASSIFICATION][fields.CLASSIFICATION_DISTRIBUTION_TYPE_ID],
    [fields.CLASSIFICATION_IS_WHOLESALER]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_IS_WHOLESALER],
    [fields.BLOCK_ORDER]: item[fields.BLOCK][fields.BLOCK_ORDER],
    [fields.BLOCK_DATE_UTC]: item[fields.BLOCK][fields.BLOCK_DATE_UTC],
    [fields.CUSTOMER_EQUIPMENTS]: item?.[fields.EQUIPMENT][fields.CUSTOMER_EQUIPMENTS]?.map(equipment => ({
      id: equipment.id,
    })),
    [fields.CUSTOMER_ASSORTMENT]: item?.[fields.ASSORTMENT][fields.CUSTOMER_ASSORTMENT][0]?.id 
      ? [{
          id: item?.[fields.ASSORTMENT][fields.CUSTOMER_ASSORTMENT][0]?.id,
        }] 
      : [],
    partnerFunctionId: item.partnerFunctionId,
    hierarchies: item.hierarchies?.map(h => ({
      id: h.id,
    })),
    wholesalers: item.wholesalers?.map(w => ({
      id: w.id,
      isDefault: w.isDefault,
    })),
    customerTerritories: item.customerTerritories?.map(cT => ({
      territory: {
        id: cT.territory.id,
      },
      territoryAssignment: {
        id: cT.territoryAssignment.id,
      },
    })),
    [fields.COMMENTS]: item[fields.COMMENTS],
  };

  const formData = new FormData();
  formData.append('command', JSON.stringify(form));

  files.forEach((file) => {
    formData.append('files', file);
  });

  return formData;
}, 'error', (req) => {
  if (req.body) {
    req.headers['Content-Type'] = 'multipart/form-data'
  }
});

const setNullValueIfNeeded = (value) => {
  return value === "" ? null : value;
}

export const updateItem = (item) => async (dispatch) => {

}

export const updateItemSwagger = defaultActions.updateItemSwagger('Customers_Put', actions, item => {
  const files = [];
  const createCustomerFiles = [];
  const updateCustomerFiles = [];
  
  item[fields.CUSTOMER_FILES].forEach(a => {
    if (a?.action === "create") {
      a.file && files.push(a.file)

      createCustomerFiles.push({
        fileName: a.file?.name || "n/a",
      });
    } else if (a?.action === "update") {
      updateCustomerFiles.push({
        id: a.id,
      });
    }
  });

  const form = {
    id: item.id,
    [fields.DELETE_CUSTOMER_FILES]: item[fields.DELETE_CUSTOMER_FILES],
    [fields.CREATE_CUSTOMER_FILES]: createCustomerFiles,
    [fields.UPDATE_CUSTOMER_FILES]: updateCustomerFiles,
    [fields.GENERAL_INFO_CUSTOMER_NUMBER]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_NUMBER],
    [fields.GENERAL_INFO_CUSTOMER_NAME]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_NAME],
    [fields.GENERAL_INFO_CUSTOMER_LONG_NAME]: setNullValueIfNeeded(item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_LONG_NAME]),
    [fields.GENERAL_INFO_CUSTOMER_EMAIL]: setNullValueIfNeeded(item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_EMAIL]),
    [fields.GENERAL_INFO_CUSTOMER_TELEPHONE]: setNullValueIfNeeded(item[fields.GENERAL_INFO][fields.GENERAL_INFO_CUSTOMER_TELEPHONE]),
    [fields.GENERAL_INFO_LANGUAGE_ID]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_LANGUAGE_ID],
    [fields.GENERAL_INFO_DELIVERY_LEAD_TIME]: item[fields.GENERAL_INFO][fields.GENERAL_INFO_DELIVERY_LEAD_TIME],
    [fields.UPDATE_CONTACT_PERSONS]: item[fields.GENERAL_INFO][fields.CONTACT_PERSON].map((person) => ({
      ...(person[fields.CONTACT_PERSON_ID] ? {[fields.CONTACT_PERSON_ID]: person[fields.CONTACT_PERSON_ID]} : {}),
      [fields.CONTACT_PERSON_NAME]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_NAME]),
      [fields.CONTACT_PERSON_ROLE]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_ROLE]),
      [fields.CONTACT_PERSON_EMAIL]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_EMAIL]),
      [fields.CONTACT_PERSON_TELEPHONE]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_TELEPHONE]),
      [fields.CONTACT_PERSON_TELEPHONE_TWO]: setNullValueIfNeeded(person[fields.CONTACT_PERSON_TELEPHONE_TWO]),
      [fields.CONTANCT_PERSON_DEFAULT]: person[fields.CONTANCT_PERSON_DEFAULT],
      [fields.CONTACT_PERSON_ACTIVE]: !person[fields.CONTACT_PERSON_ACTIVE],
    })),
    [fields.BILLING_ADDRESS]: {
      [fields.BILLING_ADDRESS_CITY_ID]: item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_CITY_ID],
      [fields.BILLING_ADDRESS_POSTAL_CODE]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_POSTAL_CODE]),
      [fields.BILLING_ADDRESS_STREET]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_STREET]),
      [fields.BILLING_ADDRESS_HOUSE_NUMBER]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_HOUSE_NUMBER]),
      [fields.BILLING_ADDRESS_LONGITUDE]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_LONGITUDE]),
      [fields.BILLING_ADDRESS_LATITUDE]: setNullValueIfNeeded(item[fields.BILLING_ADDRESS][fields.BILLING_ADDRESS_LATITUDE]),
    },
    [fields.SHIP_TO_ADDRESS]: {
      [fields.SHIP_TO_ADDRESS_CITY_ID]: item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_CITY_ID],
      [fields.SHIP_TO_ADDRESS_POSTAL_CODE]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_POSTAL_CODE]),
      [fields.SHIP_TO_ADDRESS_STREET]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_STREET]),
      [fields.SHIP_TO_ADDRESS_HOUSE_NUMBER]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_HOUSE_NUMBER]),
      [fields.SHIP_TO_ADDRESS_LONGITUDE]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_LONGITUDE]),
      [fields.SHIP_TO_ADDRESS_LATITUDE]: setNullValueIfNeeded(item[fields.SHIP_TO_ADDRESS][fields.SHIP_TO_ADDRESS_LATITUDE]),
    },
    ...(item[fields.CREDIT_DATA] ? {
      [fields.CREDIT_DATA]: {
        [fields.CREDIT_DATA_BANK_ACCOUNT]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_BANK_ACCOUNT]),
        [fields.CREDIT_DATA_VAT_NUMBER]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_VAT_NUMBER]),
        [fields.CREDIT_DATA_CREDIT_LIMIT]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_CREDIT_LIMIT]),
        [fields.CREDIT_DATA_CREDIT_EXPOSURE]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_CREDIT_EXPOSURE]),
        [fields.CREDIT_DATA_OPEN_BALANCE]: setNullValueIfNeeded(item[fields.CREDIT_DATA][fields.CREDIT_DATA_OPEN_BALANCE]),
      }
    } : {}),
    [fields.CLASSIFICATION_TYPE_ID]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_TYPE_ID],
    [fields.CLASSIFICATION_SEGMENT_ID]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_SEGMENT_ID],
    [fields.DIVISION_ID]: item[fields.CLASSIFICATION][fields.DIVISION_ID],
    [fields.CLASSIFICATION_PAYER_NUMBER]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_PAYER_NUMBER],
    [fields.CLASSIFICATION_REBATE_NUMBER]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_REBATE_NUMBER],
    [fields.CLASSIFICATION_DISTRIBUTION_TYPE_ID]:
      item[fields.CLASSIFICATION][fields.CLASSIFICATION_DISTRIBUTION_TYPE_ID],
    [fields.CLASSIFICATION_IS_WHOLESALER]: item[fields.CLASSIFICATION][fields.CLASSIFICATION_IS_WHOLESALER],
    [fields.BLOCK_ORDER]: item[fields.BLOCK][fields.BLOCK_ORDER],
    [fields.BLOCK_DATE_UTC]: item[fields.BLOCK][fields.BLOCK_DATE_UTC],
    createCustomerEquipments: item?.createCustomerEquipments?.map(equipment => ({
      id: equipment.id,
    })),
    deleteCustomerEquipments: item?.deleteCustomerEquipments?.map(equipment => ({
      id: equipment.id,
    })),
    [fields.CUSTOMER_ASSORTMENT]: item?.[fields.ASSORTMENT][fields.CUSTOMER_ASSORTMENT][0]?.id 
      ? [{
          id: item?.[fields.ASSORTMENT][fields.CUSTOMER_ASSORTMENT][0]?.id,
        }] 
      : [],
    partnerFunctionId: item.partnerFunctionId,
    hierarchies: item.hierarchies?.map(h => ({
      id: h.id,
    })),
    wholesalers: item.wholesalers?.map(w => ({
      id: w.id,
      isDefault: w.isDefault,
    })),
    customerTerritories: item.customerTerritories?.map(cT => ({
      territory: {
        id: cT.territory.id,
      },
      territoryAssignment: {
        id: cT.territoryAssignment.id,
      },
    })),
    [fields.COMMENTS]: item[fields.COMMENTS],

  };

  const formData = new FormData();
  formData.append('command', JSON.stringify(form));

  files.forEach((file) => {
    formData.append('files', file);
  });

  return formData;
}, 'error', (req) => {
  if (req.body) {
    req.headers['Content-Type'] = 'multipart/form-data'
  }
});

export const clear = () => defaultActions.clear(actions);
export const clearCustomer = () => defaultActions.clearItem(actions)

export const clearError = () => defaultActions.clearError(actions);

const getRegionFromCitySwagger = async action => {
  try {
    console.log('Get region from city')
    let entities;
    const operationID = "Cities_GetByIdRegion";
    const params = action ?? {};

    const userOrgUnit = getCurrentUserOrganizationUnit();
    const response = await getCurrentAccessToken();

    params["OrganizationUnit"] = userOrgUnit;

    await swaggerClient.then(client =>
      client
        .execute({
          spec: client.spec,
          operationId: operationID,
          parameters: params,
          securities: {
            authorized: {
              bearerAuth: response.accessToken,
            },
          },
          responseContentType: "application/json",
        })
        .then(data => {
          entities = data.body.id;
        }),
    );

    return entities;
  } catch (errors) {
    //error.clientMessage = error.clientMessage = `Can't load `;
    const error = defaultActions.requestErrorHandling(errors, "Error occurred!");
    //yield put(actions.catchError({ error, callType: defaultActions.loadingProp }));
  }
};

export const getCountryFromRegionSwagger = async action => {
  const operationID = "Regions_GetByIdCountry";

  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  console.log('Get country from region')
  const params = {
    Id: action,
    OrganizationUnit: userOrgUnit,
  };

  let entities;

  await swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        entities = response.body.id;
      })
      .catch(errors => {
        const error = defaultActions.requestErrorHandling(errors, "Error occurred!");
        //dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );

  return entities;
};

export const sequencialSaga = filter => {

  const sequencialSagaWorker = function*(action) {
    console.log('SEQUENCIAL')

    try {
      const regionId = yield getRegionFromCitySwagger({ Id: action.payload.Id });
      const countryId = yield getCountryFromRegionSwagger(regionId);
  
      yield put(regionsActions.fetchCountryRegionsSwagger({ Id: countryId }));
      yield put(citiesActions.fetchRegionCitiesSwagger({ Id: regionId }));
  
      if (action.payload.Property === "shipToAddress") {
        yield put(actions.regionFromCityFetchedShipToAddress(regionId));
        yield put(actions.countryFromRegionsFetchedShipToAddress(countryId));
      } else {
        yield put(actions.regionFromCityFetchedBillToAddress(regionId));
        yield put(actions.countryFromRegionsFetchedBillToAddress(countryId));
      }
      //yield put(actions.startCall({ callType: "loadingProp" }));
    } finally {
      SagasManager.closeChannel()
    }
  };

  const sequencialSagaWatcher = function*() {
    yield takeLatest("customers/loadSequencialData", sequencialSagaWorker);
  };
  
  SagasManager.addSagaToRoot(sequencialSagaWatcher);

  return {
    type: "customers/loadSequencialData",
    payload: filter,
  };
};

// export function* sequencialSagaWorker(action) {
//   console.log('SEQUENCIAL')
//   const regionId = yield getRegionFromCitySwagger({ Id: action.payload.Id });
//   const countryId = yield getCountryFromRegionSwagger(regionId);

//   yield put(regionsActions.fetchCountryRegionsSwagger({ Id: countryId }));
//   yield put(citiesActions.fetchRegionCitiesSwagger({ Id: regionId }));

//   if (action.payload.Property === "shipToAddress") {
//     yield put(actions.regionFromCityFetchedShipToAddress(regionId));
//     yield put(actions.countryFromRegionsFetchedShipToAddress(countryId));
//   } else {
//     yield put(actions.regionFromCityFetchedBillToAddress(regionId));
//     yield put(actions.countryFromRegionsFetchedBillToAddress(countryId));
//   }
// };

// export default function* watchSequencialSaga() {
//   yield takeLatest("customers/loadSequencialData", sequencialSagaWorker);
// }


/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const getTableItems = (params, data) => async dispatch => {
  dispatch(actions.startArticlesCall({ callType: "equipmentLoading" }));

  try {
    const res = await get(`/api/appmasterdata/customers/customerEquipments/${data.id}`, params);
    let items = res.items ? res.items : []; // Items Array
    const itemsCount = res.totalCount ? res.totalCount : 0; // Items Count

    // Аdd archived items for adding
    if (data.addedTableItems.length) {
      const archivedArticles = [];
      items.forEach(item => {
        data.addedTableItems.forEach(article => {
          if (item.id === article.id) {
            item.action = "created";
          } else {
            archivedArticles.push(article);
          }
        });
      });

      if (items.length === 0) {
        items = [...new Set([...data.addedTableItems])];
      } else {
        items = [...new Set([...items, ...archivedArticles])];
      }
    }

    // Remove archived items for deletion
    if (data.removedTableItems.length) {
      items.forEach(item => {
        data.removedTableItems.forEach(article => {
          if (item.id === article.id) {
            item.action = "deleted";
          }
        });
      });
    }

    dispatch(actions.setAssortmentArticles({ items, itemsCount }));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "equipmentLoading", errorProp: "equipmentError" }));
  }
};

// Reset equipment table items
export const resetCustomerEquipment = () => async dispatch => {
  dispatch(actions.resetCustomerEquipment());
};

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const deleteTableItem = (id, data) => dispatch => {
  const deletedArticleIds = cloneDeep(data.removedTableItems);
  let {addedTableItems} = data;
  let newArticles = cloneDeep(data.items);

  const deleteMethod = (a) => {
    a.children = a.children.map(deleteMethod)
    if (a.id === id) {
      a.action = "deleted";
      const isNew = Boolean(addedTableItems.find(item => item.id === a.id));
      if (isNew) {
        addedTableItems = addedTableItems.filter(item => item.id !== a.id);
      } else {
        deletedArticleIds.push(a);
      }
      return a;
    }
    return a;
  }

  newArticles = newArticles.map(deleteMethod);

  dispatch(actions.deleteAssortmentArticles({ newArticles, deletedArticleIds, addedArticles: addedTableItems }));
  return deletedArticleIds;
};

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const addTableItem = (newArticles, newArticleIds) => dispatch => {
  dispatch(actions.addAssortmentArticles({ newArticles, newArticleIds }));
};

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
// Items from search
export const getSearchedItems = (params, assortmentId) => async dispatch => {
  try {
    const res = await get("/api/appmasterdata/customers/customerEquipmentsExcept", {
      Filter: params.filter,
      CustomerId: params.assortmentId ? params.assortmentId : "00000000-0000-0000-0000-000000000000",
    });
    dispatch(actions.setSearchArticles(res));
  } catch (errors) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
  }
};
// Reset searched items
export const resetSearchedItems = () => async dispatch => {
  dispatch(actions.resetSearchedItems());
};


// -----------------------------------
export const getSearchedClients = (params) => async dispatch => {
  try {
    dispatch(actions.setSearchCustomersLoading(true));
    const res = await get("/api/appmasterdata/customers/customerSearchList", params);
    dispatch(actions.setSearchCustomers(res));
    dispatch(actions.setSearchCustomersLoading(false));
  } catch (errors) {
    dispatch(actions.setSearchCustomersLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
  }
};



// ----------------------------------------

export const getFilters = () => async dispatch => {
  try {
    const distribution = await get("/api/appmasterdata/customer/distributionTypes/complexSearch");
    const segments = await get("/api/appmasterdata/customer/segments/complexSearch");
    const types = await get("/api/appmasterdata/customer/types/complexSearch");
    const divisions = await get("/api/appmasterdata/customer/divisions/complexSearch");

    dispatch(
      setFilter(
        constructInitialFilters([
          ...(distribution || []),
          ...(segments || []),
          ...(types || []),
          ...(divisions || []),
        ])
      )
    )
  } catch (errors) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
}

export const setFilter = filters => defaultActions.setFilter(filters, actions);
