import * as fields from "./fields";

export const PATHS = {
    ROOT: 'websales',
    SHOP: 'general',
}

export const ENDPOINTS = {
    ROOT: "/api/websales",
    ORDERS: '/taskOrderList',
    ORDER_ITEMS: '/orderItems',
    COPY_LAST: '/lastOrderItems',
    FILTERS: '/api/activity/ordertypes'
}

export const CURRENCY = 'BGN ';

export const TABS = [fields.GENERAL, fields.ITEMS];