/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";
import { selectors, actions } from "../../../../../app/modules/Dashboard/_redux";
import { PERIODS } from "../../../../../app/modules/Dashboard/pages/constants";
import { useTranslation } from "react-i18next";

const PeriodsDropdown = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedPeriod = useSelector(selectors.useSelectedPeriod, shallowEqual);
    // const [show, setShow] = useState(false);
    
    const onTabClick = (key) => {
        dispatch(actions.setSelectedPeriod(key));
    }

    return (
        <div className='NavigationMenuContainer w-100'>
            <ul
                className="nav nav-tabs nav-pills grey-nav-pills align-items-center"
                role="tablist"
            >
                {Object.entries(PERIODS).map(([key, value], idx) => (
                    <li className="nav-item" onClick={() => onTabClick(key)} key={idx}>
                        <span
                            className={`nav-link cursor-pointer ${key === selectedPeriod && "active"}`}
                            data-toggle="tab"
                            role="tab"
                            aria-selected={key === selectedPeriod}
                        >
                            {t(value)}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
        // <Dropdown
        //   show={show}
        //   alignRight
        //   onClick={() => setShow(!show)}
        //   onMouseEnter={() => setShow(true)}
        //   onMouseLeave={() => setShow(false)}
        // >
        //     <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        //         <div className="topbar-item">
        //             <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mr-1">
                        
        //                 <SvgIcon
        //                     iconLocation="/media/svg/icons/Custom/ic-calendar.svg"
        //                     size="4"
        //                     customClasses="mr-2"
        //                     title="Database"
        //                     bootstrapColor="primary"
        //                 />
        //                 <span className="symbol symbol-35 symbol-light-light">
        //                     <span className="symbol-label fw-400 w-100 p-3 text-primary">
        //                         {selectedPeriod.charAt(0).toUpperCase() + selectedPeriod.slice(1)}
        //                     </span>
        //                 </span>
        //             </div>
        //         </div>
        //     </Dropdown.Toggle>
        //     <Dropdown.Menu
        //         className="p-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
        //         style={{ marginTop: "-1rem" }}
        //     >
        //         <ul className="navi navi-hover py-4">
        //             <li className="px-6 pointer-events-none text-muted">Periods</li>
        //             <div className="dropdown-divider"/>
        //             {Object.entries(PERIODS).map(([key, value]) => (
        //                 <li
        //                     key={key}
        //                     className={clsx("navi-item pointer", {
        //                         active: key === selectedPeriod,
        //                     })}
        //                 >
        //                     <span
        //                         onClick={() => {
        //                             dispatch(actions.setSelectedPeriod(key));
        //                         }}
        //                         className="navi-link"
        //                     >
        //                         <span className="navi-text">{value.charAt(0).toUpperCase() + value.slice(1)}</span>
        //                     </span>
        //                 </li>
        //             ))}
        //         </ul>
        //     </Dropdown.Menu>
        // </Dropdown>
    );
}

export default PeriodsDropdown;