import { RootState } from "../../../../../redux/store";


export const error = (state: RootState) => state.routePlanning.error;
export const errorAction = (state: RootState) => state.routePlanning.errorAction;
export const validationErrors = (state: RootState) => state.routePlanning.validationErrors;

export const routesLoading = (state: RootState) => state.routePlanning.itemsLoading;
export const getItems = (state: RootState) => state.routePlanning.items;
export const getItemsCount = (state: RootState) => state.routePlanning.itemsCount;
export const getError = (state: RootState) => state.routePlanning.error;

export const rangeOptions = (state:RootState) => state.routePlanning.rangeOptions;
export const rangeOptionsLoading = (state:RootState) => state.routePlanning.rangeOptionsLoading;

export const territoryOptions = (state:RootState) => state.routePlanning.territoryOptions;
export const territoryOptionsLoading = (state:RootState) => state.routePlanning.territoryOptionsLoading;

export const locations = (state:RootState) => state.routePlanning.locations;
export const locationsLoading = (state:RootState) => state.routePlanning.locationsLoading;

export const tasks = (state:RootState) => state.routePlanning.tasks;
export const tasksLoading = (state:RootState) => state.routePlanning.tasksLoading;

export const vehicleTypes = (state:RootState) => state.routePlanning.vehicleTypes;
export const vehicleTypesLoading = (state:RootState) => state.routePlanning.vehicleTypesLoading;

export const tourPlanStatus = (state:RootState) => state.routePlanning.tourPlanStatus;
export const tours = (state:RootState) => state.routePlanning.tours;
export const tourPlanLoading = (state:RootState) => state.routePlanning.toursPlanLoading;

export const selectedTour = (state:RootState) => state.routePlanning.selectedTour;

export const userLocation = (state:RootState) => state.routePlanning.userLocation;


export const savedTour = (state:RootState) => state.routePlanning.savedTour;

export const getFilters = (state: RootState) => state.routePlanning.filters;
export const getAssignedOptions = (state:RootState) => state.routePlanning.assignedOptions