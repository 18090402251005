import {es, enUS as en, bg, Locale } from 'date-fns/locale'
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const languages = [
    {
        lang: "en",
        name: "English",
        flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg")
    },
    {
        lang: "bg",
        name: "Bulgarian",
        flag: toAbsoluteUrl("/media/svg/flags/168-bulgaria.svg")
    },
    {
        lang: "es",
        name: "Spanish",
        flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg")
    },
    // {
    //     lang: "de",
    //     name: "German",
    //     flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
    // },
    // {
    //     lang: "fr",
    //     name: "French",
    //     flag: toAbsoluteUrl("/media/svg/flags/195-france.svg")
    // }
];



type IDateLocals = {
  [key: string]: Locale
}

export const DATE_LOCALES_MAP:IDateLocals = {
  es: es,
  bg: bg,
  en: en
} 